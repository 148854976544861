import react from 'react'
import styled from 'styled-components'
import Layout from './Layout'

const CreateChannelContainer = styled.div``

export const CreateChannel = () => {

    return <Layout>
        <CreateChannelContainer></CreateChannelContainer>
    </Layout>


}
export const LOGIN = '/';
export const DASHBOARD = '/dashboard';
export const ACTIVATEUSER = '/activate_user';
export const AVAILABLETOKEN = '/available_token';
export const ENABLETOKEN = '/enable_token';
export const CREATECHANNEL = '/create_channel';
export const MINTTOKEN = '/mint_token';
export const ROLES = '/roles';
export const EMPLOYEES = '/employees';
export const FEATURES = '/features';
export const FEATURES_TABLE = "/features-table"
export const EMPLOYEEINFO = '/employees/employee_info'
export const DIGITALWALLET = "/tokens/digitals"
import {
    SET_USER
} from "../shared/constants";

const initalState = {
    cognitoUser: null
}

function useReducer(state = initalState, action) {
    switch (action.type) {
        case SET_USER: {
            return { ...state, cognitoUser: action.payload };
        }
        default:
            return state;
    }
}

export default useReducer;
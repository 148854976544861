import axios from "axios";
import PROSPERUS_USERS from '../mock-data/users.json'

export const getEmployees = (offset, limit) => {
    //Stoped in (offset)10 and need (limit)12 more registers,
    return new Promise((resolve, reject) => {
        const users = PROSPERUS_USERS.ProsperUsEmployees.data;
        const pages = users.length / limit;
        const list = users.slice(offset, offset + limit);
        const registers = users.length;

        setTimeout(() => {
            return resolve({
                list,
                pages,
                registers
            })
        }, Math.floor(Math.random() * 3500) + 300)
    })

}
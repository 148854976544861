import React from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

export const GenericModal = ({ children, title, toggle, isOpen, ...args }) => {

    return (
        <Modal isOpen={isOpen} centered={true} {...args} >
            <ModalHeader >{title}</ModalHeader>
            <ModalBody>
                {children[0]}
            </ModalBody>
            <ModalFooter>
                {children[1]}
            </ModalFooter>
        </Modal>
    );

}
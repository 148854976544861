import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import translationEN_US from "./shared/en.json";
import translationES_GT from "./shared/es.json";


const resources = {
    "en-US": {
        translation: translationEN_US,
    },
    "es-GT": {
        translation: translationES_GT,
    }
};

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: 'en-US', //default language
        keySeparator: false,
        interpolation: {
            escapeValue: false,
        },
        preload: true
    });

export default i18n;
import React, { useState, useEffect } from 'react';
import { Button, Input, Table } from 'reactstrap';
import { Modal } from 'react-bootstrap';
import * as FaIcons from 'react-icons/fa';
import Searchbar from '../../Components/SearchBar/Searchbar';
import axios from 'axios';
import Layout from '../Layout';
import colors from '../../shared/colors';
import { useTranslation } from 'react-i18next';

function EmployeeInfo() {
    const [showDisableModal, setShowDisableModal] = useState(false);
    const [showAddMemberModal, setShowAddMemberModal] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [listMember, setListMember] = useState([]);
    const { t } = useTranslation();

    const handleClose = () => setShowDisableModal(false);
    const handleShow = () => setShowDisableModal(true);
    const handleCloseAddMember = () => setShowAddMemberModal(false);
    const handleShowAddMember = () => setShowAddMemberModal(true);

    const getMember = () => {
        axios({
            method: "get",
            url: ""
        }).then((res) => {
            setListMember(res.data.member)
        }).catch((error) => error)
    }

    const createTag = () => {
        if (listMember.length > 1) {
            axios({
                method: "post",
                url: "",
                data: {
                    idUser: "",
                    key: "",
                    value: ""
                },
                timeout: 100 * 25
            }).then((res) => {
                console.log(res.data)
            }).catch((error) => error)
        }
    }


    return (
        <Layout>

            <div style={{ display: 'flex', marginLeft: "18%", marginRight: "25%", padding: "2%" }}>
                <div>
                    <FaIcons.FaUserCircle size={150} color={`${colors.goblin}`} />
                </div>
                <div style={{ marginLeft: "10%", flexWrap: "wrap" }}>
                    <div style={{ marginTop: "5%" }}>
                        <label style={{ fontSize: 18, fontWeight: "bold", color: `${colors.goblin}` }}>{t("employees-info-userName")}{": "}</label>
                        <label>Joseph Mora </label>
                    </div>
                    <div style={{ marginTop: "5%" }}>
                        <label style={{ fontSize: 18, fontWeight: "bold", color: `${colors.goblin}` }}>{t("employees-info-email")}{": "}</label>
                        <label>jmora@gmail.com </label>
                    </div>
                    <div style={{ marginTop: "5%" }}>
                        <label style={{ fontSize: 18, fontWeight: "bold", color: `${colors.goblin}` }}>{t("employees-info-phone")}{": "}</label>
                        <label>7777-7777 </label>
                    </div>
                    <div style={{ marginTop: "5%" }}>
                        <label style={{ fontSize: 18, fontWeight: "bold", color: `${colors.goblin}` }}>{t("employees-info-address")}{": "}</label>
                        <label>235th. street </label>
                    </div>
                    <div style={{ marginTop: "5%" }}>
                        <label style={{ fontSize: 18, fontWeight: "bold", color: `${colors.goblin}` }}>{t("employees-info-gender")}{": "}</label>
                        <label>Male </label>
                    </div>
                    <div style={{ marginTop: "5%" }}>
                        <label style={{ fontSize: 18, fontWeight: "bold", color: `${colors.goblin}` }}>{t("employees-info-tag")}{": "}</label>
                        <label>--- </label>
                    </div>
                </div>

            </div>
            <div style={{ flexDirection: "row", marginLeft: "18%", marginRight: "25%", }}>
                <hr style={{ height: 2, width: "auto" }} />
                <h3 style={{ textAlign: "center" }}>Team</h3>
                <Button style={{ textAlign: "center", backgroundColor: `${colors.supernova}`, borderColor: `${colors.supernova}`, marginBottom: "5%" }}
                    onClick={handleShowAddMember}>
                    {t("employees-info-addMemberButton")}{" +"}
                </Button>
                <Modal size='md' show={showAddMemberModal} onHide={handleCloseAddMember}>
                    <Modal.Header closeButton>
                        <Modal.Title>{t("employees-info-modal-add-title")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Searchbar keyword={searchQuery} placeholder={t("employees-info-modal-add-placeholder")} onChange={(e) => setSearchQuery(e)} />
                        <div style={{
                            flexDirection: "row", alignContent: "space-between", marginLeft: "15%", marginRight: "15%",
                        }}>
                            <label>{t("employees-info-modal-add-resultName")}{": "}</label>
                            <label>Juan Carrera</label>
                            <label style={{ marginLeft: '4px' }}>{t("employees-info-modal-add-resultEmail")}{": "}</label>
                            <label>jcarreras@gmail.com </label>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button style={{ backgroundColor: `${colors.supernova}`, borderColor: `${colors.supernova}` }} variant="primary" onClick={handleCloseAddMember}>
                            {t("employees-info-modal-add-addButton")}
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Table striped>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>
                                {t("employees-info-table-firstName")}
                            </th>
                            <th>
                                {t("employees-info-table-lastName")}
                            </th>
                            <th>
                                {t("employees-info-table-email")}
                            </th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>1</td>
                            <td>Jeff</td>
                            <td>Gomez</td>
                            <td>jgomdez@gmail.com</td>
                            <td><Button onClick={handleShow} style={{ backgroundColor: `${colors.summerGreen}`, borderColor: `${colors.summerGreen}` }}>{t("employees-info-table-disableButton")}</Button></td>
                        </tr>
                    </tbody>
                </Table>
            </div>
            <Modal size='md' show={showDisableModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {t("employees-info-modal-disable-title")}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {t("employees-info-modal-disable-message")}
                </Modal.Body>
                <Modal.Footer>
                    <Button style={{ backgroundColor: `${colors.goblin}` }} variant="primary" onClick={handleClose}>
                        {t("employees-info-modal-disable-yes")}
                    </Button>
                </Modal.Footer>
            </Modal>
        </Layout>
    )
}

export default EmployeeInfo
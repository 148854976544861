import styled, { css } from "styled-components"

const CardContainer = styled.div`
  width: ${props => props.size?.w ? props.size.w : "300px"};
  height: ${props => props.size?.h ? props.size.h : "300px"};
  border-radius: 10px;
  box-shadow: ${props => props.inset ? "inset 0px 5px 10px 2px rgba(0, 0, 0, 0.5)" : "0px 5px 10px 0px rgba(0, 0, 0, 0.5)"};
  
  ${props => !props.inset && css`
    &:hover {
    transform: translateY(-5px);
    box-shadow: 0px 8px 15px 2px rgba(0, 0, 0, 0.25);
    }
  `}
`


export const Card = ({ children, inset, size, style, id }) => {

    return (
        <CardContainer
            id={id}
            inset={inset}
            size={size}
            style={style}>
            {children}
        </CardContainer>
    )

}
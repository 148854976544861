import react from 'react';
import Layout from '../Layout';
import { Goals } from './Goals';
import { useEffect } from 'react';

export const FeaturesTable = () => {

    return (
        <Layout style={{ minWidth: '350px' }}>

            <div style={{
                maxWidth: "1500px",
                margin: "0 auto",
                display: "grid",
                gap: "1rem",
                gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
                marginBottom: '75px'
            }}>
                <Goals />
            </div>
        </Layout>
    )
}
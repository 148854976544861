import { FormGroup, Input, Label, Tooltip, UncontrolledTooltip } from "reactstrap"
import { Card } from "../../Components/Card"
import { useState } from "react";
import colors from "../../shared/colors";
import { LoaderWithContainer } from "../../Components/Loader/LoaderWithContainer";
import { useLoader } from "../../hooks/useLoader";
import { useEffect } from "react";
import { getGoalStatus } from "../../api/feature";
import { useTranslation } from "react-i18next";


export const Goals = (props) => {
    const [goalState, setGoalState] = useState(false);
    const [isCardEnabled, setIsCardEnabled] = useState(true);
    const { isLoading, disabledLoading, enableLoading } = useLoader(true);
    const { t } = useTranslation();

    useEffect(() => {
        getGoalStatus("any Token")
            .then(response => {
                setIsCardEnabled(response.data.companyIsActive);
                setGoalState(response.data.companyHasActiveGoals);
                disabledLoading()
            })
            .catch(x => disabledLoading())
    }, [])

    const handleDisabledText = isCardEnabled || isLoading ? styles.titleDisabled : styles.titleActive;
    const handleCardEnable = isCardEnabled || isLoading;


    const handleGoalsSwitch = () => {
        enableLoading();
        setTimeout(() => {
            disabledLoading();
            setGoalState(!goalState)
        }, 2000)
    }

    return (
        <div className="pt-5">
            <div style={{
                maxWidth: "1200px",
                margin: "0 auto",
                display: "grid",
                gap: "1rem",
                gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
            }}>
                <Card
                    id={"UncontrolledTooltipExample"}
                    inset={handleCardEnable}
                    size={{ h: "150px" }}
                    style={{ padding: '10px' }}
                >
                    <h4 id={"toolTip-goal"} style={handleDisabledText}>
                        {t("features-goals-titleh4")}
                    </h4>
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '70%', alignItems: 'center' }}>
                        <LoaderWithContainer
                            isLoading={isLoading}
                        >
                            <FormGroup
                                switch
                                disabled={handleCardEnable}
                            >
                                <Input
                                    type="switch"
                                    disabled={handleCardEnable}
                                    checked={goalState}
                                    onClick={() => handleGoalsSwitch()}
                                />
                                <Label check> {t("features-goals-label")}</Label>
                            </FormGroup>
                        </LoaderWithContainer>
                    </div>
                </Card>
                {isCardEnabled &&
                    <UncontrolledTooltip
                        placement="top"
                        target="UncontrolledTooltipExample"
                    >{t("features-goals-card-disabled")}
                    </UncontrolledTooltip>
                }

            </div>
        </div>
    )

}

const styles = {
    titleDisabled: { color: `${colors.Label}`, opacity: '0.4', textAlign: 'center' },
    titleActive: { color: `${colors.Label}`, textAlign: 'center' }
}
import axios from 'axios';

export const getDomain = async (domainName) => {
    return await axios({
        method: 'get',
        url: `${process.env.REACT_APP_API_URL}/userpool?domain=${domainName}`,
        timeout: 1000 * 25,
    }).then((res) => {
        return res.data;
    }).catch((err) => {
        return `ERROR ${err.toString()}`;
    })

} 

import react from 'react';
import Layout from './Layout';
import styled from 'styled-components';


const AvailableTokensContainer = styled.div`
`


const AvailableToken = (props) => {

    return (
        <Layout>
            <AvailableTokensContainer>
            </AvailableTokensContainer>
        </Layout>
    )
}

export default AvailableToken;
import React from 'react';
import DashboardContentGraph from '../Components/ContentGraph/DashboardContentGraph';
import Layout from './Layout';

const Dashboard = () => {
    return (
        <div>
            <Layout>
                <DashboardContentGraph />
            </Layout>
        </div>
    )
}

export default Dashboard
import React, { useState } from 'react';
import styled from 'styled-components';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import colors from '../../shared/colors';
import { useTranslation } from 'react-i18next';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const DashboardBarGraph = () => {
    const { t } = useTranslation()
    const options = {
        responsive: true,
        indexAxis: "y",
        plugins: {
            legend: {
                labels: {
                    color: colors.heavyMetal,
                },
            },
        },
        scales: {
            x: {
                ticks: {
                    color: colors.heavyMetal,
                },
                grid: {
                    color: colors.white,
                },
            },
            y: {
                ticks: {
                    color: colors.heavyMetal,
                },
                grid: {
                    color: colors.white,
                },
            },
        },
    };

    const [data, setData] = useState({
        labels: [
            t("month-january"),
            t("month-february"),
            t("month-march"),
            t("month-april"),
            t("month-may"),
            t("month-june"),
            t("month-july"),
            t("month-august"),
            t("month-september"),
            t("month-october"),
            t("month-november"),
            t("month-december"),
        ],
        datasets: [
            {
                label: "Activations",
                backgroundColor: colors.goblin,
                data: [11, 13, 10, 12, 15, 14, 14, 11, 13, 10, 12, 15],
            },
            {
                label: "Deactivations",
                backgroundColor: colors.ecstasy,
                data: [0, 2, 2, 1, 3, 4, 1, 2, 4, 0, 1, 4],
            },
        ],
    })
    return (
        <DashboardBarGraphContent>
            <h5 style={{ marginTop: "20%" }} class="text-center">{t("components-graph-title")}</h5>
            <Bar options={options} data={data} />
        </DashboardBarGraphContent>
    )
};

const DashboardBarGraphContent = styled.div`
width: 90%;
margin-left: 5%;
margin-right: 5%
`

export default DashboardBarGraph
import {
  LOGIN, DASHBOARD, ACTIVATEUSER, AVAILABLETOKEN, ENABLETOKEN, CREATECHANNEL, MINTTOKEN, ROLES,
  EMPLOYEES, EMPLOYEEINFO, FEATURES_TABLE, DIGITALWALLET
} from './Config/navigation/paths';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import CompanyDomain from './Views/CompanyDomain';
import Dashboard from '../src/Views/Dashboard';
import ActivateUser from '../src/Views/ActivateUser';
import AvailableToken from "./Views/AvailableToken"
import { CreateChannel } from './Views/CreateChannel';
import EnableToken from '../src/Views/EnableToken';
import { MintToken } from './Views/MintToken';
import Roles from '../src/Views/Roles';
import Employees from './Views/Employee/Employees';
import ProtectedRoute from './hooks/ProtectedRoutes';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { Auth } from 'aws-amplify';
import {
  setUser
} from './redux/actions';
import EmployeeInfo from '../src/Views/Employee/EmployeeInfo';
import { FeaturesTable } from './Views/Features/FeaturesTable';
import { useTranslation } from 'react-i18next';

import './App.css';
import { DigitalWallet } from './Views/Token/DigitalWallets';

function App() {
  const { i18n } = useTranslation();
  const availableLanguages = ["en-US", "es-GT"]

  const dispatch = useDispatch()
  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then(cognitoUser => {
        dispatch(setUser(cognitoUser));
      })
      .catch(err => {
        if (err.toString() == "The user is not authenticated") {
          dispatch(setUser(null))
        }
      })
  }, [])

  useEffect(() => {

    const browserLanguage = availableLanguages.includes(navigator.language) ? navigator.language : "en-US";
    i18n.changeLanguage(browserLanguage);
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route path={LOGIN} element={<CompanyDomain />} />
        <Route
          path={DASHBOARD}
          element={<ProtectedRoute>
            <Dashboard />
          </ProtectedRoute>}
        />
        <Route
          path={ACTIVATEUSER}
          element={<ProtectedRoute>
            <ActivateUser />
          </ProtectedRoute>
          } />

        <Route
          path={AVAILABLETOKEN}
          element={<ProtectedRoute>
            <AvailableToken />
          </ProtectedRoute>
          } />

        <Route
          path={ENABLETOKEN}
          element={<ProtectedRoute>
            <EnableToken />
          </ProtectedRoute>
          } />

        <Route
          path={CREATECHANNEL}
          element={<ProtectedRoute>
            <CreateChannel />
          </ProtectedRoute>
          } />

        <Route
          path={MINTTOKEN}
          element={<ProtectedRoute>
            <MintToken />
          </ProtectedRoute>
          } />

        <Route
          path={ROLES}
          element={<ProtectedRoute>
            <Roles />
          </ProtectedRoute>
          } />

        <Route
          path={EMPLOYEES}
          element={<ProtectedRoute>
            <Employees />
          </ProtectedRoute>
          } />

        <Route
          path={EMPLOYEEINFO}
          element={<ProtectedRoute>
            <EmployeeInfo />
          </ProtectedRoute>
          } />

        <Route
          path={FEATURES_TABLE}
          element={<ProtectedRoute>
            <FeaturesTable />
          </ProtectedRoute>
          } />

        <Route
          path={DIGITALWALLET}
          element={
            <ProtectedRoute>
              <DigitalWallet />
            </ProtectedRoute>
          } />
      </Routes>
    </BrowserRouter>
  );
}

export default App;

import React from 'react';
import * as BiIcons from 'react-icons/bi';

const Searchbar = ({ keyword, onChange, placeholder, action }) => {
    const BarStyle = { width: "20rem", background: "#F0F0F0" };
    return (
        <div style={{
            display: 'flex', flexDirection: "row", border: '5px solid #d9dbdd', marginLeft: "15%",
            marginRight: "15%", borderRadius: 12
        }}>
            <input
                style={BarStyle}
                key="search-bar"
                value={keyword}
                placeholder={placeholder}
                onChange={(e) => onChange(e.target.value)}
            />
            <BiIcons.BiSearch size={24} onClick={action} />
        </div>
    );
}

export default Searchbar
import { useState } from "react"


export const useTable = (isLoadingt = false, limit = 10) => {
    const [isLoading, setIsloading] = useState(isLoadingt);
    const [dataTable, setDataTable] = useState({
        offset: 0,
        pages: 0,
        data: [],
        onPage: 0
    })


    const orderAscending = (a, b) => (orderBy) => {
        if (a[orderBy] > b[orderBy]) {
            return 1;
        }
        if (a[orderBy] < b[orderBy]) {
            return -1;
        }
        return 0;
    }

    const orderDescending = (a, b) => (orderBy) => {
        if (a[orderBy] < b[orderBy]) {
            return 1;
        }
        if (a[orderBy] > b[orderBy]) {
            return -1;
        }
        return 0;
    }

    const updateTable = (
        data,
        pages,
        offset,
        orderBy,
        onPage
    ) => {
        if (!Array.isArray(data)) throw ("data set should be string");
        if (!Object.keys(data[1]).includes(orderBy)) throw ("Unsuported Key, Verify the names");

        const sortedTable = data.sort((a, b) => orderAscending(a, b)("name"))

        setDataTable(state => {
            return {
                ...state,
                data: sortedTable,
                pages,
                offset,
                onPage
            }
        });
    }


    const sortByKey = (orderBy, sortAscending = true) => {
        const sortedTable = sortAscending ?
            dataTable.data.sort((a, b) => orderAscending(a, b)(orderBy))
            :
            dataTable.data.sort((a, b) => orderDescending(a, b)(orderBy))

        setDataTable(state => {
            return {
                ...state,
                data: sortedTable
            }
        });
    }

    const tableIsLoading = () => setIsloading(true);
    const tableLoaded = () => setIsloading(false);

    const getOffset = (limit, inPage) => {
        const offset = limit * (inPage);
        setDataTable(state => ({ ...state, offset }))
        return offset;
    }

    return {
        offset: dataTable.offset,
        pages: dataTable.pages,
        dataTable: dataTable.data,
        isLoading,
        onPage: dataTable.onPage,
        updateTable,
        tableIsLoading,
        tableLoaded,
        getOffset,
        sortByKey
    }



}
import react from 'react'
import styled from 'styled-components'
import Layout from './Layout'

const MintContainer = styled.div`
`

export const MintToken = () => {


    return <Layout>
        <MintContainer></MintContainer>
    </Layout>
}
import React from 'react';
import DashboardBarGraph from './DashboardBarGraph';
import DashboardPieGraph from './DashboardPieGraph';
import DashboardLocationGraph from './DashboardLocationGraph';
import DashboardInvestmentGraph from './DashboardInvestmentGraph';
import styled from 'styled-components';


const DashboardContentGraph = () => {
    return (
        <DashboardGraph>
            <BarGraph>
                <DashboardBarGraph />
            </BarGraph>
            <PieGraph>
                <DashboardPieGraph />
                <DashboardLocationGraph />
            </PieGraph>
            <InvestmentGraph>
                <DashboardInvestmentGraph />
            </InvestmentGraph>
        </DashboardGraph>
    )
}

const DashboardGraph = styled.div`
    display: flex;
    align-content: center;
    justify-content: center;
    flex-direction: column;    
    margin-left: 18%;
    margin-right: 25%;
    border-width: 1px;
    border-radius: 4px;
    height: 100%;
    
    `

const BarGraph = styled.div`
     margin-top: 60%;
    `
const InvestmentGraph = styled.div`
     margin-bottom: 10%;
    `

const PieGraph = styled.div`
    display:flex;
    margin-top: 5%;
    align-content: center;
    justify-content: center;
    `

export default DashboardContentGraph
import React, { useEffect, useState } from 'react';

import TopBar from '../../Components/TopBar';
import { Modal, Button } from 'react-bootstrap';
import { Table, Tooltip, Form, FormGroup, Label, Input, Col } from 'reactstrap';
import * as MdIcons from 'react-icons/md';
import * as IoIcons from 'react-icons/io';
import * as BiIcons from 'react-icons/bi';
import axios from 'axios';
import PaginationComponent from '../../Components/Pagination/PaginationComponent';
import { ROUTE } from '../../api/route';
import Layout from '../Layout';
import colors from '../../shared/colors';
import { useTranslation } from 'react-i18next';

const Employees = () => {
    const [employeeData, setEmployeeData] = useState([]);
    const [show, setShow] = useState(false);
    const [modeModal, setModeModal] = useState(false);
    const [tooltipOpenCreate, setTooltipOpenCreate] = useState(false);
    const [tooltipOpenUpdate, setTooltipOpenUpdate] = useState(false);
    const [tooltipOpenDelete, setTooltipOpenDelete] = useState(false);
    const [tooltipOpenShow, setTooltipOpenShow] = useState(false);
    const urlEmployee = process.env.REACT_APP_API_URL + ROUTE.STORE_USER;
    const [infoEmployee, setInfoEmployee] = useState({
        id: "",
        name: "",
        lastName: "",
        email: "",
        phone: "",
        address: "",
        birth: "",
        gender: ""
    })

    const toggleCreate = () => setTooltipOpenCreate(!tooltipOpenCreate);
    const toggleUpdate = () => setTooltipOpenUpdate(!tooltipOpenUpdate);
    const toggleDelete = () => setTooltipOpenDelete(!tooltipOpenDelete);
    const toggleShow = () => setTooltipOpenShow(!tooltipOpenShow);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const { t } = useTranslation();

    const handleSubmit = (evt) => {
        evt.preventDefault();
    }

    const handleChange = (evt) => {
        const target = evt;
        const { name, value } = target;

        const newValues = {
            ...infoEmployee,
            [name]: value,
        };

        setInfoEmployee(newValues);
    }

    useEffect(() => {
        getEmployees()
    }, [])

    const getEmployees = () => {
        axios({
            method: "get",
            url: "https://jsonplaceholder.typicode.com/users"
        }).then((res) => {
            setEmployeeData(res.data);
            console.log(employeeData);

        })
            .catch((error) => console.log(error.message))
    }

    const addEmployees = async (e) => {
        e.preventDefault();
        await axios({
            method: 'post',
            url: urlEmployee,
            data: infoEmployee,
            timeout: 1000 * 25,
        }).then((res) => {
            handleClose();
        }).catch((error) => {
            console.log(error.message)
        })
    }


    const editEmployee = async (e) => {
        e.preventDefault();
        await axios({
            method: "put",
            url: urlEmployee, // + `/${idEdit}`,
            data: employeeData,
            timeout: 1000 * 25,
        }).then((response) => {

        }).catch((error) => {

        })
    }

    const disableUser = async () => {
        await axios({
            method: 'post',
            url: urlEmployee,
            data: {
                //email: user.email,
                //status: !user.status,
            },
        }).then((res) => {
        }).catch((err) => {
        })
    }

    const createForm = () => {
        return (
            <Form onSubmit={handleSubmit}>
                <FormGroup row>
                    <Label
                        for="idEmployee"
                        sm={2}
                    >
                        {t("employees-list-modal-create-form-label-id")}
                    </Label>
                    <Col sm={10}>
                        <Input
                            id="idEmployee"
                            value={infoEmployee.id}
                            onChange={handleChange}
                            name="idEmployee"
                            placeholder={t("employees-list-modal-create-form-placeholder-id")}
                            type="id"
                        />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label
                        for="name"
                        sm={2}
                    >
                        {t("employees-list-modal-create-form-label-name")}
                    </Label>
                    <Col sm={10}>
                        <Input
                            id="name"
                            name="name"
                            placeholder={t("employees-list-modal-create-form-placeholder-name")}
                            type="email"
                        />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label
                        for="lastname"
                        sm={2}
                    >
                        {t("employees-list-modal-create-form-label-LastName")}
                    </Label>
                    <Col sm={10}>
                        <Input
                            id="lastname"
                            name="lastname"
                            placeholder={t("employees-list-modal-create-form-placeholder-LastName")}
                            type="email"
                        />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label
                        for="email"
                        sm={2}
                    >
                        {t("employees-list-modal-create-form-label-email")}
                    </Label>
                    <Col sm={10}>
                        <Input
                            id="email"
                            name="email"
                            placeholder={t("employees-list-modal-create-form-placeholder-email")}
                            type="email"
                        />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label
                        for="phone"
                        sm={2}
                    >
                        {t("employees-list-modal-create-form-label-phone")}
                    </Label>
                    <Col sm={10}>
                        <Input
                            id="phone"
                            name="phone"
                            placeholder={t("employees-list-modal-create-form-placeholder-phone")}
                            type="tel"
                        />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label
                        for="address"
                        sm={2}
                    >
                        {t("employees-list-modal-create-form-label-address")}
                    </Label>
                    <Col sm={10}>
                        <Input
                            id="address"
                            name="address"
                            placeholder={t("employees-list-modal-create-form-placeholder-adress")}
                            type="email"
                        />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label
                        for="date of birth"
                        sm={2}
                    >
                        {t("employees-list-modal-create-form-label-birth")}
                    </Label>
                    <Col sm={10}>
                        <Input
                            id="date of birth"
                            name="date of birth"
                            placeholder={t("employees-list-modal-create-form-placeholder-birth")}
                            type="date"
                        />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label
                        for="gender"
                        sm={2}
                    >
                        {t("employees-list-modal-create-form-label-gender")}
                    </Label>
                    <Col sm={10}>
                        <Input
                            id="gender"
                            name="gender"
                            type="select"
                        >
                            <option>
                            {t("employees-list-modal-create-form-option-gender-female")}
                            </option>
                            <option>
                            {t("employees-list-modal-create-form-option-gender-male")}
                            </option>
                        </Input>
                    </Col>
                </FormGroup>
            </Form>
        )
    }

    return (
        <Layout>


            <div style={{ marginBottom: "5%" }}>
                <Button id="btnCreate" style={{ backgroundColor: `${colors.goblin}`, border: "none" }} onClick={handleShow}>
                    <MdIcons.MdCreate style={{ paddingRight: "5%" }} size={25} />
                    {t("employees-list-button-create")}
                </Button>
                <Tooltip
                    placement="right"
                    isOpen={tooltipOpenCreate}
                    autohide={false}
                    target="btnCreate"
                    toggle={toggleCreate}
                >
                    {t("employees-list-button-create-tooltip")}
                </Tooltip>
                <Modal size='lg' show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>{modeModal ? t("employees-list-modal-create-title-create") : t("employees-list-modal-create-title-update")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {createForm()}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button style={{ backgroundColor: `${colors.ecstasy}`, borderColor: `${colors.ecstasy}` }} onClick={handleClose}>
                            {t("employees-list-modal-create-button-create")}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>
                            {t("employees-list-table-firstName")}
                        </th>
                        <th>
                            {t("employees-list-table-lastName")}
                        </th>
                        <th>
                            {t("employees-list-table-Email")}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {employeeData.map((employee) => {
                        return (
                            <tr key={employee.id}>
                                <td>{employee.id}</td>
                                <td>{employee.name}</td>
                                <td>{employee.username}</td>
                                <td>{employee.email}</td>
                                <td><BiIcons.BiShow id="btnShow" color={`${colors.heavyMetal}`} size={30} onClick={() => window.location.href = "/employees/employee_info"} /></td>
                                <Tooltip
                                    placement="top"
                                    isOpen={tooltipOpenShow}
                                    autohide={false}
                                    target="btnShow"
                                    toggle={toggleShow}
                                >
                                    {t("employees-list-table-show")}
                                </Tooltip>
                                <td><MdIcons.MdUpdate id="btnUpdate" color={`${colors.heavyMetal}`} size={30} onClick={handleShow} /></td>
                                <Tooltip
                                    placement="top"
                                    isOpen={tooltipOpenUpdate}
                                    autohide={false}
                                    target="btnUpdate"
                                    toggle={toggleUpdate}
                                >
                                    {t("employees-list-table-update")}
                                </Tooltip>
                                <td><MdIcons.MdDelete id="btnDelete" color={`${colors.heavyMetal}`} size={30} /></td>
                                <Tooltip
                                    placement="top"
                                    isOpen={tooltipOpenDelete}
                                    autohide={false}
                                    target="btnDelete"
                                    toggle={toggleDelete}
                                >
                                    {t("employees-list-table-delete")}
                                </Tooltip>
                            </tr>
                        )
                    })}
                </tbody>
            </Table>
            <div>
                <PaginationComponent data={employeeData} />
            </div>


        </Layout>
    )
}



export default Employees
import { useState } from "react";


export const useModal = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [message, setMessage] = useState("");
    const closeModal = () => setIsOpen(false);
    const openModal = (m = "") => {
        setIsOpen(true)
        setMessage(m)
    }
    const toggle = () => setIsOpen(o => !o)
    return { isOpen, closeModal, openModal, message, toggle }
}
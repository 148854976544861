import axios from "axios";
import companyGoal from './companyGoal.json';


const callGetEndpoint = (url, token, isLocal) => {
    if (isLocal) {
        const responseTime = Math.floor(Math.random() * 3500) + 300;
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                if (token === "") return reject({ status: 403, msg: "TOKEN NEEDED" })
                return fetch(url)
                    .then(x => resolve(x))
            }, responseTime)
        })
    }
    return axios.get(
        process.env.REACT_APP_API_URL + url,
        {
            headers: {
                authorizationToken: token,
            },
        });
};


export const getGoalStatus = (authToken = "") => {
    return new Promise((resolve, reject) => {
        callGetEndpoint(
            // `${process.env.REACT_APP_API_URL}/getCompaniesList`,
            "./companyGoal.json",
            authToken,
            process.env.REACT_APP_ISLOCAL
        ).then((response) => {
            resolve(companyGoal);
        });
    });
};
import React, { useState } from 'react';
import { Navbar, NavbarBrand } from 'reactstrap';
import logoProsperus from "../assets/prosperus_logo_login.svg";
import * as FiIcons from 'react-icons/fi';
import * as MdIcons from 'react-icons/md';
import * as BsIcons from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import { Auth } from 'aws-amplify';
import { setUser, storeLanguageDialogs } from '../redux/actions';
import styled from 'styled-components';
import colors from '../shared/colors';
import { useTranslation } from 'react-i18next';


const TopNavigation = styled.div`
  background-color: ${colors.ecstasy};
  position: fixed;
  top: 0;
  width: 100%;
  min-width: 602px;
  z-index: 999;
`

const LanguageType = styled.span`
    color: ${colors.ecstasy};
    font-size: 12pt;
    align-self: center;
    font-weight: bold;

   &:hover {
     color: ${colors.white};
     cursor: pointer;
   }
`
const IconContainer = styled.div`
    width: 350px;
    backgroundColor: red;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
`

const TopBar = () => {
    const dispatch = useDispatch();
    const { i18n } = useTranslation();

    const [color, setColor] = useState({
        logout: colors.ecstasy,
    });

    const logOut = async () => {
        await Auth.signOut();
        dispatch(setUser(null));
    }

    const updateColor = (item, color) => setColor(c => ({ ...c, [item]: color }))

    return (
        <TopNavigation >
            <Navbar
                className="my-2"
                style={{ background: `${colors.heavyMetal}` }}

            >
                <NavbarBrand href="/">
                    <img
                        alt="logo"
                        src={logoProsperus}
                        style={{
                            height: "60px",
                            width: "300px"
                        }}
                    />
                </NavbarBrand>
                <IconContainer >
                    <LanguageType
                        onClick={() => {
                            i18n.changeLanguage('en-US');
                        }}
                    >{"en-US"}
                    </LanguageType>
                    <LanguageType
                        onClick={() => {
                            i18n.changeLanguage('es-GT');
                        }}
                    >{"es-GT"}
                    </LanguageType>

                    <FiIcons.FiInbox color={colors.ecstasy} size={30} />
                    <MdIcons.MdNotificationsNone color={colors.ecstasy} size={30} />
                    <BsIcons.BsThreeDotsVertical
                        style={{ cursor: 'pointer' }}
                        color={colors.ecstasy}
                        size={30} />

                    <FiIcons.FiLogOut
                        onClick={() => logOut()}
                        color={color.logout}
                        onMouseEnter={() => updateColor("logout", `${colors.white}`)}
                        onMouseLeave={() => updateColor("logout", colors.ecstasy)} //opacity in 0.6
                        size={30}
                        style={{ cursor: 'pointer' }} />

                </IconContainer>
            </Navbar>

        </TopNavigation>
    )
}


export default TopBar
import React, { useRef, useState } from 'react';
import backgroundImage from "../assets/login_bg.jpg";
import logoProsperus from "../assets/prosperus_logo_login.svg";
import { Button } from 'reactstrap';
import { getDomain } from '../api/domain';
import { Amplify, Auth } from 'aws-amplify'
import { setUser } from '../redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useNavigate, } from 'react-router-dom';
import RingLoader from 'react-spinners/HashLoader';
import colors from '../shared/colors';
import { useTranslation } from "react-i18next";


const CompanyDomain = () => {
    const domainRef = useRef();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [validationField, setValidationField] = useState(false);
    const { cognitoUser } = useSelector(state => state.useReducer);
    const { t } = useTranslation();

    async function handleSubmit(e) {
        e.preventDefault();
        if (domainRef.current.value === "") return setValidationField(true);
        setLoading(true);
        setValidationField(false)
        getDomain(domainRef.current.value).then(res => {
            const awsConfig = Amplify.configure({
                region: res.region,
                userPoolId: res.userpool_id,
                userPoolWebClientId: res.webclientid,
                oauth: {
                    domain: res.cognito_domain,
                    scope: ["phone",
                        "email",
                        "openid"],
                    redirectSignIn: `${process.env.REACT_APP_REDIRECT_SIGNIN}`,
                    redirectSignOut: `${process.env.REACT_APP_REDIRECT_SIGNOUT}`,
                    responseType: "code",
                }
            });

            Amplify.configure(awsConfig);
            localStorage.setItem("awsConfig", JSON.stringify(awsConfig))
            Auth.federatedSignIn();
            setLoading(false);
        });
    }

    useEffect(() => {
        const awsConfig = localStorage.getItem('awsConfig');
        if (typeof awsConfig !== "string") return;
        Amplify.configure(JSON.parse(awsConfig));

        Auth.currentAuthenticatedUser()
            .then(cognitoUser => {
                dispatch(setUser(cognitoUser));
                navigate("/dashboard")
            })
            .catch(err => {
                if (err.toString() == "The user is not authenticated") {
                    dispatch(setUser(cognitoUser))
                }
            })
    }, [])

    return (
        <div
            style={{ display: "flex", flexDirection: 'column', alignItems: "center", position: "relative" }}>
            <img src={backgroundImage} alt="backgroundImage"
                style={{
                    width: "100vw", height: "100vh"
                }} />
            <img src={logoProsperus} alt="logo"
                style={{ width: "32%", zIndex: 1, position: "absolute", marginTop: "10%" }} />
            <div className='form-container'>
                <h3 style={{ fontSize: "28pt", textAlign: "center", color: colors.green, fontWeight: 'bold' }}>
                    {t("companyDomain-loginSession")}</h3>
                <label className='company-label'>{t("companyDomain-label")}</label>
                <form onSubmit={handleSubmit} className={'domain-form'}>
                    <input
                        ref={domainRef}
                        type='text'
                        placeholder={t("companyDomain-inputPlaceHolder")}
                        className="form-control"
                        style={{ width: '14.5em' }}
                    />
                    {validationField && <span style={{ color: 'red' }}>{t("companyDomain-validationText")}</span>}
                    <Button type='submit' className='next-btn-domain' disabled={loading}>
                        {loading ?
                            <>
                                <RingLoader color={colors.white} size={20} />
                                <span style={{ marginLeft: '10px' }}>{t("companyDomain-loader")}</span>
                            </>
                            :
                            t("companyDomain-loggingButton")
                        }

                    </Button>
                </form>
            </div>
        </div >
    )
}

export default CompanyDomain;
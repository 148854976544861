import {
    SET_USER
} from '../shared/constants'

export const setUser = user => dispatch => {
    dispatch({
        type: SET_USER,
        payload: user,
    });
};


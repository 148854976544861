import {
    DASHBOARD, ACTIVATEUSER, ROLES, MINTTOKEN, AVAILABLETOKEN, ENABLETOKEN,
    CREATECHANNEL, EMPLOYEES, FEATURES_TABLE, EMPLOYEEINFO, DIGITALWALLET
} from '../../Config/navigation/paths';
import iconManageEmployee from "../../assets/icon_manage_employee.svg";
import iconDashboard from "../../assets/icon_dashboard.svg";
import iconTokenAdmin from "../../assets/icon_token_admin.svg";
import iconMessaging from "../../assets/icon_messaging.svg";
import { GrConfigure } from "react-icons/gr";
import { MdGeneratingTokens } from "react-icons/md";
import { BsFillBriefcaseFill, BsWallet2 } from "react-icons/bs";
import { FiUserCheck } from "react-icons/fi";
import { CgArrowsExchangeAlt } from "react-icons/cg";
import { VscActivateBreakpoints } from "react-icons/vsc";
import { BsPeopleFill, BsFillGearFill } from "react-icons/bs";
import { BiNetworkChart } from "react-icons/bi";
import { HiOutlineInformationCircle } from "react-icons/hi";

// Be aware the following icons cannot be color changed:
// gr

const _navModel = [
    {
        title: "sideBar-dashboard",
        path: DASHBOARD,
        icon: <img src={iconDashboard} alt="iconDashboard" style={{ width: '30px', height: '30px', margin: 0, padding: 0 }} />
    },
    {
        title: "sideBar-manageEmployees-title",
        icon: <img src={iconManageEmployee} alt="iconManage" style={{ width: '30px', height: '30px', margin: 0, padding: 0 }} />,
        children: [
            {
                title: "sideBar-manageEmployees-children-activateDeactive",
                path: ACTIVATEUSER,
                icon: <VscActivateBreakpoints />
            },
            {
                title: "sideBar-manageEmployees-children-platformRoles",
                path: ROLES,
                icon: <BiNetworkChart />
            },
        ],
    },
    {
        title: "sideBar-manageTokens-title",
        icon: <img src={iconTokenAdmin} alt="iconToken" style={{ width: '30px', height: '30px', margin: 0, padding: 0 }} />,
        children: [
            {
                title: "sideBar-manageTokens-children-mint",
                path: MINTTOKEN,
                icon: <MdGeneratingTokens />
            },
            {
                title: "sideBar-manageTokens-children-available",
                path: AVAILABLETOKEN,
                icon: <BsFillBriefcaseFill />
            },
            {
                title: "sideBar-manageTokens-children-enable",
                path: ENABLETOKEN,
                icon: <FiUserCheck />
            },
            {
                title: "sideBar-manageTokens-children-digitalWallets",
                path: DIGITALWALLET,
                icon: <BsWallet2 />
            }
        ],
    },
    {
        title: "sideBar-messaging-title",
        icon: <img src={iconMessaging} alt="iconMessaging" style={{ width: '30px', height: '30px', margin: 0, padding: 0 }} />,
        children: [
            {
                title: "sideBar-messaging-children-createChannel",
                path: CREATECHANNEL,
                icon: <CgArrowsExchangeAlt />
            },
        ],
    },
    {
        title: "sideBar-employees-title",
        icon: <img src={iconManageEmployee} alt="iconManage" style={{ width: '30px', height: '30px', margin: 0, padding: 0 }} />,
        children: [
            {
                title: "sideBar-employees-children-list",
                path: EMPLOYEES,
                icon: <BsPeopleFill />
            },
            {
                title: "sideBar-employees-children-info",
                path: EMPLOYEEINFO,
                icon: <HiOutlineInformationCircle />
            },
        ],
    },
    {
        title: "sideBar-companyConfig-title",
        icon: <GrConfigure />,
        children: [
            {
                title: "sideBar-companyConfig-children-functions",
                path: FEATURES_TABLE,
                icon: <BsFillGearFill />,
            },
        ],
    },
]

export default _navModel;
import { useEffect } from "react"
import { Card, CardBody, Collapse, Table } from "reactstrap"
import Layout from "../Layout"
import { getEmployees } from "../../shared/api/manageTokens"
import { LoaderWithContainer } from "../../Components/Loader/LoaderWithContainer"
import PaginationComponent from "../../Components/Pagination/PaginationComponent"
import { useTranslation } from "react-i18next"
import { useTable } from "../../hooks/useTable"
import styled, { css } from "styled-components"
import colors from "../../shared/colors"
import { useState } from "react"
import { VscArrowSmallDown, VscArrowSmallUp } from 'react-icons/vsc'
import { GenericModal } from "../../Components/GenericModal"
import { useModal } from "../../hooks/useModal"


const TableHeader = styled.th`
    ${props => props.rightLine && css`
        border-right: 1px solid black;
    `}
    border-bottom: 1px solid black;
    &:hover{
        cursor: pointer;
        transform: translateY(-3px);
        text-decoration: underline;
        text-underline-offset: 5px;
    }
`;

const TableHeaderWithIcon = ({ title, isAscending, onClick, rightLine }) => {
    const { t } = useTranslation();

    return <TableHeader
        onClick={onClick}
        rightLine={rightLine}
    >
        {t(title)}
        {isAscending ?
            <VscArrowSmallDown color={colors.heavyMetal} size={35} />
            :
            <VscArrowSmallUp color={colors.heavyMetal} size={35} />
        }
    </TableHeader>
}


const UserModalTokens = ({ toggle, openModal, doAction }) => {
    const { t } = useTranslation();
    const user = UserModalTokens.user;


    const [tokenInfo, setTokenInfo] = useState({
        name: "",
        headers: [],
        descriptions: []
    });
    if (user === undefined) return;

    return <GenericModal
        size={"lg"}
        isOpen={openModal}
        title={user.name}
    >
        <div className="container ">
            <div className="row">
                <div className="col justify-content-rigth align-self-center">
                    <div>
                        <span style={{ ...styles.avatarBadge, fontSize: '20px' }}> {user.avatar}</span>
                        <span style={{ ...styles.userModalDescriptionTitle, marginLeft: '35px' }}>{user.systemName}</span>
                    </div>
                </div>
                <div className="col" style={{ borderLeft: `2px solid ${colors.summerGreen}` }}>
                    <div className="d-flex flex-row" style={{ marginLeft: '20px' }}>
                        <div className="d-flex flex-column" style={{ marginRight: '10px' }}>
                            <span style={styles.userModalDescriptionTitle}>{"Index"}</span>
                            <span style={styles.userModalDescriptionTitle}>{"Id"}</span>
                            <span style={styles.userModalDescriptionTitle}>{"Department"}</span>
                        </div>
                        <div className="d-flex flex-column">
                            <span style={styles.userModalDescriptionContent}>{user.idx}</span>
                            <span style={styles.userModalDescriptionContent}>{user.id}</span>
                            <span style={styles.userModalDescriptionContent}>{user.department}</span>
                        </div>

                    </div>
                </div>
            </div>
            <hr style={{
                marginTop: "1rem",
                marginBottom: "1rem",
                border: "0",
                borderTop: `2px solid ${colors.summerGreen}`
            }} />
            <div>
                <div className="row">
                    <div className="col">
                        <Table hover>
                            <thead>
                                <tr>
                                    <th>
                                        {t("manageTokens-digitalWallets-modal-table-token")}
                                    </th>
                                    <th>
                                        {t("manageTokens-digitalWallets-modal-table-name")}
                                    </th>
                                    <th>
                                        {t("manageTokens-digitalWallets-modal-table-date")}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {user.tokens.map((token, idx) => {
                                    const tokenId = Math.floor(Math.random() * 400)
                                    return <tr
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => {


                                            const tokenValues = Object.entries(token).filter(i => !["name", "date"].includes(i[0]))
                                            const headers = Object.keys(Object.fromEntries(tokenValues))
                                            const descriptions = Object.values(Object.fromEntries(tokenValues))

                                            setTokenInfo({
                                                name: token.name,
                                                headers,
                                                descriptions
                                            })

                                        }}
                                    >
                                        <th scope="row">
                                            {tokenId}
                                        </th>
                                        <td>
                                            {token.name}
                                        </td>
                                        <td>
                                            {token.date}
                                        </td>
                                    </tr>
                                })}
                            </tbody>
                        </Table>
                    </div>
                    <div className="col">
                        <span style={{ ...styles.titleModalDescription }}>{tokenInfo?.name}</span>
                        <div className="col mt-3" >
                            <div className="d-flex flex-row" style={{ marginLeft: '20px' }}>
                                <div className="d-flex flex-column" style={{ marginRight: '15px' }}>
                                    {tokenInfo.headers.map(e => <span style={styles.userModalTokenDescription}>{e}</span>)}

                                </div>
                                <div className="d-flex flex-column">
                                    {tokenInfo.descriptions.map(e => <span style={styles.userModalTokenDescription}>{e}</span>)}
                                </div>

                            </div>
                        </div>
                        {tokenInfo.headers.length > 0 &&
                            <button style={{
                                backgroundColor: colors.goblin,
                                color: colors.white,
                                border: `2px solid ${colors.goblin}`, borderRadius: '2px', padding: '4px'
                            }}>{t("manageTokens-digitalWallets-modal-detailToken-delete")}
                            </button>
                        }
                    </div>
                </div>
            </div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', width: '100%', marginRight: '80%' }}>
            <button
                type="button"
                style={{ backgroundColor: colors.summerGreen, color: colors.white, border: `2px solid ${colors.summerGreen}`, borderRadius: '2px', padding: '4px' }}
                onClick={() => {
                    toggle()
                    setTokenInfo({ name: '', headers: [], descriptions: [] })
                }}
            >
                {t("manageTokens-digitalWallets-modal-close")}
            </button>

        </div>
    </GenericModal >


}

export const DigitalWallet = (props) => {
    const { t } = useTranslation();

    const [isAscendingSorted, setIsAscendingSorted] = useState({
        name: true,
        systemName: true,
        department: true,
        avatar: true
    })

    const NUMBER_OF_REGISTERS = 50;
    const {
        pages,
        dataTable,
        isLoading,
        onPage,
        updateTable,
        tableIsLoading,
        tableLoaded,
        getOffset,
        sortByKey
    } = useTable(false, NUMBER_OF_REGISTERS)

    const { isOpen, closeModal, openModal, message, toggle } = useModal();

    const handleUpdateEmployees = (limit, inPage) => {
        tableIsLoading();
        const offset = getOffset(limit, inPage)
        getEmployees(offset, limit)
            .then(response => {

                updateTable(
                    response.list,
                    response.pages,
                    offset,
                    "name",
                    inPage
                )
                tableLoaded();
            })
            .catch(err => console.log(err))
    }

    useEffect(() => {
        handleUpdateEmployees(NUMBER_OF_REGISTERS, 0);
    }, [])

    const handleSortByKey = (key) => {
        setIsAscendingSorted(state => ({
            [key]: !isAscendingSorted[key]
        }))

        sortByKey(key, !isAscendingSorted[key]);
    }

    return <Layout>

        <div className="container mt-3">
            <LoaderWithContainer
                containerStyle={{ width: '100%', height: "200px", display: 'flex', justifyContent: 'center', alignItems: "center" }}
                isLoading={isLoading}>
                <Table hover>
                    <thead className="table">
                        <tr>
                            <TableHeader rightLine >#</TableHeader>
                            <TableHeaderWithIcon
                                title="manageTokens-digitalWallets-table-name"
                                onClick={() => handleSortByKey("name")}
                                isAscending={isAscendingSorted.name}
                                rightLine={true}
                            />

                            <TableHeaderWithIcon
                                title="manageTokens-digitalWallets-table-systemName"
                                onClick={() => handleSortByKey("name")}
                                isAscending={isAscendingSorted.name}
                                rightLine={true}
                            />


                            <TableHeaderWithIcon
                                title="manageTokens-digitalWallets-table-department"
                                onClick={() => handleSortByKey("department")}
                                isAscending={isAscendingSorted.department}
                                rightLine={true}
                            />


                            <TableHeaderWithIcon
                                title="manageTokens-digitalWallets-table-avatar"
                                onClick={() => handleSortByKey("avatar")}
                                isAscending={isAscendingSorted.avatar}
                                rightLine={false}
                            />


                        </tr>
                    </thead>
                    <tbody>
                        {dataTable.map((user, idx) => {
                            return (
                                <tr
                                    key={user.id}
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                        openModal()
                                        UserModalTokens.user = { ...user, idx: idx + 1 }
                                    }}
                                >
                                    <th>{idx + 1}</th>
                                    <th>{user.name}</th>
                                    <th>{user.systemName}</th>
                                    <th>{user.department}</th>
                                    <th>{user.avatar}</th>
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
                <PaginationComponent
                    pages={pages}
                    UpdateActualPage={(e) => {
                        handleUpdateEmployees(NUMBER_OF_REGISTERS, e.selected)
                    }}
                    onPage={onPage}
                />
            </LoaderWithContainer>
        </div>

        <UserModalTokens
            openModal={isOpen}
            toggle={toggle}
            doAction={() => console.log("")}
        />

    </Layout>
}

const styles = {
    avatarBadge: {
        display: 'inline',
        padding: '5px 15px',
        marginRight: '12px',
        borderRadius: "7px",
        backgroundColor: colors.heavyMetal,
        color: colors.white
    },
    titleModalDescription: {
        fontSize: '20px',
        display: 'flex',
        justifyContent: 'center'
    },
    userModalDescriptionTitle: {
        fontSize: '20px',
        fontWeight: 'bold'
    },
    userModalDescriptionContent: {
        fontSize: '20px',

    },
    userModalTokenDescription: {
        fontSize: '17px'

    }
}
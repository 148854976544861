import React from 'react';
import TopBar from "../Components/TopBar";
import styled from 'styled-components';
import SideBar from '../Components/SideBar/SideBar';
import NotificationBar from '../Components/NotificationBar/NotificationBar';


const ContainerLayout = styled.div`
  height: 86vh;
  position: absolute;
  top: 100px;
  left: 0;
  right: 15%;
  bottom: 0;
  margin: 10px 10px 200px 250px;
  overflow-y: auto;
`;

const Layout = (props) => {
    return (
        <div>
            <TopBar />
            <SideBar />
            <NotificationBar />

            <ContainerLayout
                className={`${props.className}`}
                style={props.style}
            >
                {props.children}
            </ContainerLayout>
        </div>
    )
}

export default Layout
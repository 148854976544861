import { Auth } from 'aws-amplify';
import React, { useEffect, useState } from 'react'
import { useSelector } from "react-redux"
import { Navigate, useLocation } from "react-router-dom"

const ProtectedRoute = ({ children }) => {
    const { cognitoUser } = useSelector((state) => state.useReducer);
    let location = useLocation();

    if (cognitoUser !== null) {
        return children
    }
    return <Navigate to="/" state={{ from: location }} replace />

};

export default ProtectedRoute;
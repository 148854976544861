import React from 'react'

import { Link } from 'react-router-dom';
import { Menu, MenuItem, SubMenu, SidebarHeader, Sidebar } from 'react-pro-sidebar';

import _navModel from './_nav';
import BACKGROUND_SIDEBAR from '../../assets/background_sidebar.png'
import colors from '../../shared/colors'
import { useTranslation } from "react-i18next";


const TheSidebar = () => {
    const { t } = useTranslation();

    return (
        <Sidebar
            image={BACKGROUND_SIDEBAR}
            sm
            style={{
                height: '88.7vh',
                marginTop: '102px',
                position: 'absolute'
            }}
            collapsedWidth={94}
        >
            {_navModel.map((firstLevel) => {
                return (
                    <Menu iconShape="shaped" >
                        {firstLevel.children ?
                            <SubMenu 
                            label={t(firstLevel.title)} 
                            icon={firstLevel.icon}
                            rootStyles={styles.secondLevel}
                             >
                                {firstLevel.children.map((secondLevel) => (secondLevel.children ?
                                    <SubMenu
                                        icon={secondLevel.icon}
                                        label={t(secondLevel.title)}>
                                        {secondLevel.children.map(deepLevel => (
                                            <Link style={styles.link} to={deepLevel.path}>
                                                <MenuItem
                                                    rootStyles={styles.menuItem}
                                                    icon={deepLevel.icon}
                                                >
                                                    {t(deepLevel.title)}
                                                </MenuItem>
                                            </Link>
                                        ))}
                                    </SubMenu>
                                    :
                                    <Link style={styles.link} to={secondLevel.path}>
                                        <MenuItem
                                            rootStyles={styles.menuItem}
                                            icon={secondLevel.icon}
                                        >
                                            {t(secondLevel.title)}
                                        </MenuItem>
                                    </Link>
                                ))}
                            </SubMenu>
                            :
                            <Link style={styles.link} to={firstLevel.path}>
                                <MenuItem
                                    rootStyles={styles.menuItem}
                                    icon={firstLevel.icon}
                                >{t(firstLevel.title)}</MenuItem>
                            </Link>
                        }
                    </Menu>
                );
            })}
        </Sidebar>
    )
}

const styles = {
    link: {
        textDecoration: "none",
        color: `${colors.heavyMetal}`
    },
    menuItem: {
        ['.ps-menu-button']: {
            width: '100%',
            height: '100%',
            '&:hover': {
                color: `${colors.white}`,
                backgroundColor: `${colors.summerGreen} !important`,
                borderLeft: `15px solid ${colors.ecstasy}`,
                ".ps-menu-label a": {
                    color:  `${colors.white} !important`,
                    paddingLeft: '10px'
                },
                ".ps-menu-icon": {
                    color: `${colors.white}`
                }
            }
        },
    },
}
export default React.memo(TheSidebar)

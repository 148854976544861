import React from 'react';
import ReactPaginate from 'react-paginate';
import { TbArrowBigLeft, TbArrowBigRight } from 'react-icons/tb'
import colors from '../../shared/colors';


const PaginationComponent = (props) => {
    const widthUsed = props.pages > 40 ? 100 : props.pages + 25;

    return (
        <div style={{ width: `${widthUsed}%` }}>
            <ReactPaginate
                pageCount={props.pages}
                nextLabel={< TbArrowBigRight color={colors.white} />}
                previousLabel={<TbArrowBigLeft color={colors.white} />}
                breakLabel="- - - - - - - - - -"
                onPageChange={(e) => {
                    props.UpdateActualPage(e);
                    return e;
                }}
                pageRangeDisplayed={10}
                marginPagesDisplayed={10}
                renderOnZeroPageCount={null}
                containerClassName="pagination"
                breakClassName={"break-pagination"}
                pageClassName='pagination-page-link'
                pageLinkClassName="pagination-page-element"
                previousClassName="pagination-previous-button"
                nextClassName="pagination-next-button"
                activeClassName='pagination-page-element-link'
                nextLinkClassName=''
                activeLinkClassName='active'
                forcePage={props.onPage}
            />
        </div>
    );
}

export default PaginationComponent
import React from 'react';
import mapImageDashboard from "../../assets/map_dashboard.png";
import { useTranslation } from 'react-i18next';

const DashboardLocationGraph = () => {
    const { t } = useTranslation();
    
    return (
        <div>
            <h5 class="text-center">{t("components-locationMap-title")}</h5>
            <img src={mapImageDashboard} alt="B3-map" style={{ width: "70%" }} preview />
        </div>
    )
}

export default DashboardLocationGraph
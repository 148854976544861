import { Spinner } from "reactstrap"

export const LoaderWithContainer = ({ children, isLoading = false, size = "50", color = "primary", style, containerStyle }) => {



    return (
        isLoading ?
            <div style={{ ...containerStyle }}>
                <Spinner
                    color={color}
                    style={{ width: `${size}px`, height: `${size}px`, ...style }}
                ></Spinner>
            </div>
            :
            children
    )



}
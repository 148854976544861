import React from 'react';
import { Chart as ChartJS, Tooltip, Title, ArcElement, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';

ChartJS.register(
    Tooltip,
    Title,
    ArcElement,
    Legend
);

const data = {
    labels: ['Savings', 'Insurance', 'Training', 'Perks', 'Stock Opts.'],
    datasets: [
        {
            data: [20, 30, 15, 25, 10],
            backgroundColor: ["#42A5F5", "#66BB6A", "#FFA726", "#1abc9c", "#fc6404"],
            hoverBackgroundColor: ["#64B5F6", "#81C784", "#FFB74D", "#28e1bd", "#fc8d04"]
        }
    ]
}

const lightOptions = {
    plugins: {
        legend: {
            labels: {
                color: '#495057'
            }
        }
    }
}

const DashboardPieGraph = () => {
    const { t } = useTranslation();
    return (
        <div>
            <h5 style={{ marginTop: "20%" }} class="text-center">{t("components-pieChar-title")}</h5>
            <Pie data={data} options={lightOptions} />
        </div>
    )
}

export default DashboardPieGraph
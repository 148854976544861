import React from 'react';
import styled from 'styled-components';

const NotificationBar = () => {
    return (
        <>
            <NotificationsBar>
                <NotificationCard>
                    <h1>Card</h1>
                </NotificationCard>
            </NotificationsBar>
        </>
    )
}


/*FiSend
FiMessageCircle
RiInformationLine*/


const NotificationsBar = styled.div`
align-content: center;
background: #fff;
justify-content: center;
position: fixed;
width: 15%;
min-width: 200px;
top: 0;
right: 0;
height: 100%;
z-index: -1;
`
const NotificationCard = styled.div`
align-self: center;
 width: 70%;
 height: 7%;
 border-style: solid;
 border-color: #f6881a;
 border-radius: 10px;
 position: absolute;
 z-index: 1;
 top: 120px;
 margin-left: 5%;
 margin-right: 5%;
`

const listMessage = [
    {
        id: 1001,
        type: "info",
        content: 'Hi, thanks for the Fancy Dinner at Linguini Pasta. There are other restaurants nearby that could be a great addition to the list of places where we can use these tokens.',
        from: 'William Daniels',
        dateTime: '2022-09-19T10:21:49.338Z',
    },
    {
        id: 1002,
        type: "warning",
        content: 'just minted 11 1yr tokens.',
        from: 'Angela Romanoff',
        dateTime: '2022-09-19T10:21:49.338Z',
    },
    {
        id: 1003,
        type: "success",
        content: 'I will be leaving the company next month, and would like to know how can I keep the Medical Insurance plan I got through Prosperus Tokens.',
        from: 'Juan Pérez',
        dateTime: '2022-09-19T10:21:49.338Z',
    },
    {
        id: 1004,
        type: "success",
        content: 'Can I transfer my Fancy Dinner token to a colleague? I won\’t be able to use it, and would like my friend in Accounting to enjoy that perk.',
        from: 'Laura Danvers',
        dateTime: '2022-09-19T10:21:49.338Z',
    },
    {
        id: 1005,
        type: "warning",
        content: 'made available the Savings Golden Plan with Bank X token.',
        from: 'Massimo Dotti',
        dateTime: '2022-09-19T10:21:49.338Z',
    },
    {
        id: 1005,
        type: "warning",
        content: 'made available the Savings Silver Plan with Bank X token.',
        from: 'Massimo Dotti',
        dateTime: '2022-09-19T10:21:49.338Z',
    },
    {
        id: 1006,
        type: "info",
        content: 'Hey, thanks for noticing. I received my College Degree token and happy to share this new achievement and look for many opportunities for growth in the company.',
        from: 'William Daniels',
        dateTime: '2022-09-19T10:21:49.338Z',
    },
    {
        id: 1007,
        type: "warning",
        content: 'just burned 4 Project on Time tokens.',
        from: 'Angela Romanoff',
        dateTime: '2022-09-19T10:21:49.338Z',
    },
    {
        id: 1008,
        type: "success",
        content: 'Can I get an Insurance token for my father or mother?. The cost is very attractive and would be wonderful to extend this benefit to my familty.',
        from: 'Laura Danvers',
        dateTime: '2022-09-19T10:21:49.338Z',
    },
    {
        id: 1009,
        type: "warning",
        content: 'just minted 8 2yr tokens.',
        from: 'Angela Romanoff',
        dateTime: '2022-09-19T10:21:49.338Z',
    },

]


export default NotificationBar
import React from 'react';
import { Line } from 'react-chartjs-2';
import styled from 'styled-components';
import {
    Chart as ChartJS,
    Title,
    Tooltip,
    LineElement,
    Legend,
    CategoryScale,
    LinearScale,
    PointElement,
    Filler
} from 'chart.js';
import { useTranslation } from 'react-i18next';
import colors from '../../shared/colors';

ChartJS.register(
    Title,
    Tooltip,
    LineElement,
    Legend,
    CategoryScale,
    LinearScale,
    PointElement,
    Filler
);


const DashboardInvestmentGraph = () => {
    const {t} = useTranslation()
    const lineData = {
        labels: [
            t("month-january"),
            t("month-february"),
            t("month-march"),
            t("month-april"),
            t("month-may"),
            t("month-june"),
            t("month-july"),
            t("month-august"),
            t("month-september"),
            t("month-october"),
            t("month-november"),
            t("month-december"),
        ],
        datasets: [
            {
                label: t('components-investmentGraph-employee'),
                data: [500, 550, 900, 890, 870, 1000, 1050, 960, 1200, 1150, 1300, 1500],
                fill: false,
                tension: .1,
                borderColor: colors.supernova
            },
            {
                label: t('components-investmentGraph-company'),
                data: [2000, 1750, 1700, 2100, 2600, 2700, 3000, 2550, 3200, 3500, 3370, 4000],
                fill: false,
                tension: .1,
                borderColor: colors.summerGreen
            },
        ]
    }

    const lineOptions = {
        responsive: true,
        plugins: {
            legend: {
                labels: {
                    color: colors.heavyMetal
                }
            }
        },
        scales: {
            x: {
                ticks: {
                    color: colors.heavyMetal
                },
                grid: {
                    color: colors.white
                }
            },
            y: {
                ticks: {
                    color: colors.heavyMetal
                },
                grid: {
                    color: colors.white
                }
            }
        }
    }
    return (
        <DashboardGraphContent>
            <h5 class="text-center">{t("components-investmentGraph-title")}</h5>
            <Line data={lineData} options={lineOptions} />
        </DashboardGraphContent>
    )
}

const DashboardGraphContent = styled.div`
width: 90%;
margin-left: 5%;
margin-right: 5%
`

export default DashboardInvestmentGraph